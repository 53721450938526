import axios from '../http'

/*
 * 户用表信息管理模块
 */
// 分页查询
export const findPage = params => {
  return axios({
    url: '/gas/meter/page',
    method: 'get',
    params
  })
}

// 从excel中导入户用流量器信息
export const importMeterExcel = data => {
  return axios({
    url: '/gas/meter/import',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

export const importMeterImg = data => {
  return axios({
    url: '/gas/meter/image/upload',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

// 分页查询
export const findRecordPage = params => {
  return axios({
    url: '/gas/record/page',
    method: 'get',
    params
  })
}

// 查看户用详细信息
export const findMeterInfo = meterId => {
  return axios({
    url: '/gas/meter/detail/' + meterId,
    method: 'get'
  })
}
