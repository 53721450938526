<template>
  <div class="about">
    <h1>欢迎使用系统</h1>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {}
  },
  methods: {
    // 打开大屏显示
    handleDatav() {
      let route = this.$router.resolve({ path: '/homeDatav' })
      window.open(route.href, '_blank')
    }
  }
}
</script>

<style scoped></style>
