// 导入所有接口
import api from './api'

const install = Vue => {
  if (install.installed) {
    return
  }
  install.installed = true
  Vue.prototype.$api = api
}
export default install
