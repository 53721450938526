import axios from '../http'

/** 报表管理模块 */

// 查询报表路径进行显示
export const findReport = params => {
  return axios({
    url: '/report/preview',
    method: 'get',
    params
  })
}
