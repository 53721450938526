import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import api from '@/http/api'
import { getIFramePath, getIFrameUrl } from '@/utils/iframe'
import Layout from '@/layout'
import home from '@/views/Home'

Vue.use(Router)

const router = new Router({
  mode: 'history', // 去掉url中的#
  routes: [
    {
      path: '/',
      name: '首页',
      component: Layout,
      children: [
        {
          path: '',
          name: '首页',
          component: home,
          meta: {
            icon: 'fa fa-windows',
            index: 0
          }
        }
      ]
    },
    {
      path: '/login',
      name: '登录',
      component: () => import('@/views/login')
    },
    {
      path: '/biogas/HelpView',
      name: '文档内容信息显示',
      component: () => import('@/views/biogas/HelpView')
    },
    {
      path: '/biogas/HouseholdHisView',
      name: '户用沼气池设施历史核验信息查看',
      component: () => import('@/views/biogas/HouseholdHisView')
    },
    {
      path: '/biogas/NoticeView',
      name: '通知公告信息显示',
      component: () => import('@/views/biogas/NoticeView')
    },
    {
      path: '/homeDatav',
      name: '大屏显示',
      component: () => import('@/views/HomeDatav')
    }
  ]
})

router.beforeEach((to, from, next) => {
  // 登录界面登录成功之后，会把用户信息保存在会话
  // 存在时间为会话生命周期，页面关闭即失效。
  let userId = sessionStorage.getItem('userId')
  if (to.path === '/login') {
    // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
    if (userId) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (!userId) {
      // 如果访问非登录界面，且户会话信息不存在，代表未登录，则跳转到登录界面
      next({ path: '/login' })
    } else {
      // 加载动态菜单和路由
      addDynamicMenuAndRoutes(userId, to)
      next()
    }
  }
})

/**
 * 加载动态菜单和路由
 */
function addDynamicMenuAndRoutes(userId, to) {
  // 处理IFrame嵌套页面
  handleIFrameUrl(to.path)
  api.menu
    .findNavTree()
    .then(res => {
      // 添加动态路由
      let dynamicRoutes = addDynamicRoutes(res.data)
      // 处理静态组件绑定路由
      handleStaticComponent(router, dynamicRoutes)
      router.addRoutes(router.options.routes)
      // 保存加载状态
      store.commit('menuRouteLoaded', true)
      // 保存菜单树
      store.commit('setNavTree', res.data)
    })
    // eslint-disable-next-line no-unused-vars
    .then(res => {
      api.user.findPermissions().then(res => {
        // 保存用户权限标识集合
        store.commit('setPerms', res.data)
      })
    })
}

/**
 * 处理IFrame嵌套页面
 */
function handleIFrameUrl(path) {
  // 嵌套页面，保存iframeUrl到store，供IFrame组件读取展示
  let url = path
  let length = store.state.iframe.iframeUrls.length
  for (let i = 0; i < length; i++) {
    let iframe = store.state.iframe.iframeUrls[i]
    if (path != null && path.endsWith(iframe.path)) {
      url = iframe.url
      store.commit('setIFrameUrl', url)
      break
    }
  }
}

function addDynamicRoutes(menuList = [], routes = []) {
  var temp = []
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].children && menuList[i].children.length >= 1) {
      temp = temp.concat(menuList[i].children)
    } else if (menuList[i].path && /\S/.test(menuList[i].path)) {
      menuList[i].path = menuList[i].path.replace(/^\//, '')
      // 创建路由配置
      var route = {
        path: menuList[i].path,
        component: null,
        name: menuList[i].menuName,
        meta: {
          icon: menuList[i].icon,
          index: menuList[i].menuId
        }
      }
      let path = getIFramePath(menuList[i].path)
      if (path) {
        // 如果是嵌套页面, 通过iframe展示
        route['path'] = path
        route['component'] = resolve =>
          require(['@/views/iframe/IFrame'], resolve)
        // 存储嵌套页面路由路径和访问URL
        let url = getIFrameUrl(menuList[i].path)
        let iFrameUrl = { path: path, url: url }
        store.commit('addIFrameUrl', iFrameUrl)
      } else {
        try {
          // 根据菜单URL动态加载vue组件，这里要求vue组件须按照url路径存储
          // 如url="sys/user"，则组件路径应是"@/views/sys/User.vue",否则组件加载不到,最后组件名称第一个字母大写，sys/User
          let array = menuList[i].path.split('/')
          let url = ''
          for (let i = 0; i < array.length; i++) {
            // 最后一个路径的首字母要大写 如：sys/user 最后路径名称是user，首字母大写 User，对能对应路由文件路径
            if (i === array.length - 1) {
              url +=
                array[i].substring(0, 1).toUpperCase() + array[i].substring(1)
            } else {
              url += array[i] + '/'
            }
          }
          route['component'] = resolve => require(['@/views/' + url], resolve)
        } catch (e) {
          // console.log(e)
        }
      }
      routes.push(route)
    }
  }
  if (temp.length >= 1) {
    addDynamicRoutes(temp, routes)
  } else {
    // console.log(routes)
  }
  return routes
}

/**
 * 处理路由到本地直接指定页面组件的情况
 * 比如'代码生成'是要求直接绑定到'Generator'页面组件
 */
function handleStaticComponent(router, dynamicRoutes) {
  for (let j = 0; j < dynamicRoutes.length; j++) {
    if (dynamicRoutes[j].name == '首页') {
      dynamicRoutes[j].component = home
      break
    }
  }
  router.options.routes[0].children = router.options.routes[0].children.concat(
    dynamicRoutes
  )
}

export default router
