import axios from '../http'

/** 用户管理模块 */

// 分页查询
export const findPage = params => {
  return axios({
    url: '/sys/user/page',
    method: 'get',
    params
  })
}

// 保存
export const save = (data, method) => {
  return axios({
    url: '/sys/user',
    method: method,
    data: data
  })
}

// 根据ID查询角色信息
export const getObj = id => {
  return axios({
    url: '/sys/user/' + id,
    method: 'get'
  })
}

// 根据ID删除用户信息
export const deleteObj = id => {
  return axios({
    url: '/sys/user/' + id,
    method: 'delete'
  })
}

// 查找用户分配对应的授权数据
export const findBizScope = (userId, ctrlType) => {
  return axios({
    url: '/sys/user/bizScope/' + userId + '/' + ctrlType,
    method: 'get'
  })
}

// 保存用户授权数据
export const saveBizScope = params => {
  return axios({
    url: '/sys/user/bizScope',
    method: 'post',
    data: params
  })
}

// 查找用户的权限标识集合
export const findPermissions = () => {
  return axios({
    url: '/sys/user/permissions',
    method: 'get'
  })
}

// 修改密码
export const updatePassword = params => {
  return axios({
    url: '/sys/user/password',
    method: 'post',
    data: params
  })
}
