import axios from '../http'

/*
 * 菜单管理模块
 */

/**
 * 查询当前登录用户可访问的导航菜单
 * @returns {AxiosPromise}
 */
export const findNavTree = () => {
  return axios({
    url: '/sys/menu/nav',
    method: 'get'
  })
}

// 查询菜单树信息
export const findMenuTree = () => {
  return axios({
    url: '/sys/menu/tree',
    method: 'get'
  })
}

// 保存
export const save = (data, method) => {
  return axios({
    url: '/sys/menu',
    method: method,
    data: data
  })
}

// 根据ID查询菜单信息
export const getObjById = id => {
  return axios({
    url: '/sys/menu/' + id,
    method: 'get'
  })
}

// 根据ID删除菜单信息
export const deleteObj = id => {
  return axios({
    url: '/sys/menu/' + id,
    method: 'delete'
  })
}
