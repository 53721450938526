import axios from '../http'

// 登录
export const login = params => {
  return axios({
    url: '/auth/token',
    method: 'POST',
    data: params
  })
}
