import axios from '../http'

/** 核验系统管理模块 */

// 分页查询
export const findPage = params => {
  return axios({
    url: '/biogas/project/page',
    method: 'get',
    params
  })
}

// 通过ID查询沼气池项目信息
export const findProjectById = projectId => {
  return axios({
    url: '/biogas/project/' + projectId,
    method: 'get'
  })
}

// 添加/更新沼气池项目信息
export const saveProject = data => {
  return axios({
    url: '/biogas/project/save',
    method: 'POST',
    data: data
  })
}

// 分页查询待审核或已审核归档项目
export const findApprovePage = params => {
  return axios({
    url: '/biogas/ver/approve/page',
    method: 'get',
    params
  })
}

// 分页查询已提交核验的记录信息(可通查询条件来区分查询待审核或已归档）
export const findVerPage = params => {
  return axios({
    url: '/biogas/ver/page',
    method: 'get',
    params
  })
}

// 查看沼气设施基本信息和核验详细信息
export const getPojectInfo = projectId => {
  return axios({
    url: '/biogas/project/detail/' + projectId,
    method: 'get'
  })
}

// 查看沼气设施基本信息和核验详细信息
export const getPojectHisInfo = projectId => {
  return axios({
    url: '/biogas/project/detail/his/' + projectId,
    method: 'get'
  })
}

// 核验公共选择器
export const getSelector = biogasType => {
  return axios({
    url: '/biogas/ver/selector/' + biogasType,
    method: 'get'
  })
}

// 下载图片信息
export const downLoadImg = id => {
  return axios({
    url: '/biogas/img/download/' + id,
    method: 'get',
    responseType: 'blob'
  })
}

// 删除核验图片信息
export const deleteImg = id => {
  return axios({
    url: '/biogas/img/delete/' + id,
    method: 'delete'
  })
}

// 提交核验项目审核
export const submitApprove = data => {
  return axios({
    url: '/biogas/ver/approve/submit',
    method: 'POST',
    data: data
  })
}

// 核验项目批量审核
export const batchApprove = data => {
  return axios({
    url: '/biogas/ver/approve/batch',
    method: 'POST',
    data: data
  })
}

// 核验项目批量回退接口
export const batchBackProject = data => {
  return axios({
    url: '/biogas/ver/approve/batchBack',
    method: 'POST',
    data: data
  })
}

// 上传户用沼气池excel
export const importExcelHousehold = data => {
  return axios({
    url: '/biogas/project/household/import',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

// 上传沼气工程excel
export const importExcelSite = data => {
  return axios({
    url: '/biogas/project/site/import',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

// 下载数据导入模板
export const downLoadTemplate = biogasType => {
  return axios({
    url: '/biogas/project/download/' + biogasType,
    method: 'get',
    responseType: 'blob'
  })
}

// 分页查询临时方法，是为了查找2021年7月15日11点32分之前丢图片的项目信息，然后进行退回重新拍照
export const findLoseImgProjectPage = params => {
  return axios({
    url: '/biogas/ver/loseimg/page',
    method: 'get',
    params
  })
}

// 分页查询复核记录信息
export const findRecheckPage = params => {
  return axios({
    url: '/biogas/project/pages',
    method: 'get',
    params
  })
}

// 保存项目巡查启动信息
export const saveProjectRecheck = data => {
  return axios({
    url: '/biogas/project/recheck',
    method: 'POST',
    data: data
  })
}

// 提交报废项目分类处理
export const classifyProject = data => {
  return axios({
    url: '/biogas/ver/classify',
    method: 'POST',
    data: data
  })
}

// 上传项目的报废材料信息
export const uploadProjectMaterial = data => {
  return axios({
    url: '/biogas/project/material/upload',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

// 保存文档内容信息
export const saveDoc = data => {
  return axios({
    url: '/biogas/doc/content',
    method: 'POST',
    data: data
  })
}

// 上传富文本编辑器里面的图片
export const uploadEditorImg = data => {
  return axios({
    url: '/biogas/doc/editorimg',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

// 上传富文本编辑器里面的视频
export const uploadEditorVideo = data => {
  return axios({
    url: '/biogas/doc/editorvideo',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

// 查询帮助中心操作指南树形结构信息
export const findDocHelpTree = params => {
  return axios({
    url: '/biogas/doc/helps',
    method: 'get',
    params
  })
}

// 查看沼气设施基本信息和核验详细信息
export const findDocById = docId => {
  return axios({
    url: '/biogas/doc/' + docId,
    method: 'get'
  })
}

// 根据ID删除菜单信息
export const deleteDoc = id => {
  return axios({
    url: '/biogas/doc/' + id,
    method: 'delete'
  })
}

//指定项目开启巡查
export const assignProjectRecheck = data => {
  return axios({
    url: '/biogas/project/assign/recheck',
    method: 'POST',
    data: data
  })
}

// 大屏显示统计沼气设施项目的总量
export const findProjectTotalCount = () => {
  return axios({
    url: '/biogas/datav/project/count',
    method: 'get'
  })
}

// 大屏显示统计户用沼气各核验情况总量
export const findProjectVerCount = () => {
  return axios({
    url: '/biogas/datav/ver/count',
    method: 'get'
  })
}

// 大屏显示统计户用沼气各核验情况总量
export const findSafeCount = () => {
  return axios({
    url: '/biogas/datav/safe/count',
    method: 'get'
  })
}

// 大屏显示：地图显示各个市级正常间歇使用情况的总量分布
export const findMapCount = () => {
  return axios({
    url: '/biogas/datav/map/count',
    method: 'get'
  })
}

// 大屏显示：统计2022年正常间歇使用项目、待查项目的核验巡查的进度
export const findRecheckCount = () => {
  return axios({
    url: '/biogas/datav/recheck/count',
    method: 'get'
  })
}

// 分页查询通知公告信息
export const findNoticePage = params => {
  return axios({
    url: '/biogas/doc/notice/page',
    method: 'get',
    params
  })
}

// 进度条形图
export const findBarChart = params => {
  return axios({
    url: '/biogas/datav/app-chart',
    method: 'get',
    params
  })
}
