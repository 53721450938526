import axios from '../http'

/** 字典管理模块 */

// 分页查询
export const findPage = params => {
  return axios({
    url: '/sys/dict/page',
    method: 'get',
    params
  })
}

// 保存
export const save = (params, method) => {
  return axios({
    url: '/sys/dict',
    method: method,
    data: params
  })
}

// 根据ID查询信息
export const getObj = id => {
  return axios({
    url: '/sys/dict/' + id,
    method: 'get'
  })
}

// 根据id删除
export const deleteObj = id => {
  return axios({
    url: '/sys/dict/' + id,
    method: 'delete'
  })
}

// 根据字典类型获得字典信息
export const findDictByType = dictType => {
  return axios({
    url: '/sys/dict/type/' + dictType,
    method: 'get'
  })
}
