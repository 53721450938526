import axios from '../http'

/*
 * 角色管理模块
 */
// 分页查询
export const findPage = params => {
  return axios({
    url: '/sys/role/page',
    method: 'get',
    params
  })
}

// 保存
export const save = (data, method) => {
  return axios({
    url: '/sys/role',
    method: method,
    data: data
  })
}

// 根据ID查询角色信息
export const getObj = id => {
  return axios({
    url: '/sys/role/' + id,
    method: 'get'
  })
}

//更新保存角色菜单信息
export const saveRoleMenus = (roleId, menuIds) => {
  return axios({
    url: '/sys/role/menu',
    method: 'put',
    params: {
      roleId: roleId,
      menuIds: menuIds
    }
  })
}

// 获取角色的菜单集合
export const findRoleMenus = roleId => {
  return axios({
    url: '/sys/role/menu/' + roleId,
    method: 'get'
  })
}

// 获取所有的角色信息
export const findAll = () => {
  return axios({
    url: '/sys/role/list',
    method: 'get'
  })
}

// 根据ID删除角色信息
export const deleteObj = id => {
  return axios({
    url: '/sys/role/' + id,
    method: 'delete'
  })
}

// 获取所有的数据访问范围类型
export const findDataScope = () => {
  return axios({
    url: '/sys/role/data_scope',
    method: 'get'
  })
}

// 当授权数据类型为指定业务时，根据业务数据地址查询业务数据
export const findBizDataScope = url => {
  return axios({
    url: url,
    method: 'get'
  })
}

// 角色分配数据权限
export const saveRoleDataScope = params => {
  return axios({
    url: '/sys/role/dataScope',
    method: 'put',
    params
  })
}
