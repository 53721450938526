/**
 * axios封装 * 请求拦截、响应拦截、错误统一处理
 * */
import axios from 'axios' // 引用axios
import router from '@/router'
import Cookies from 'js-cookie'

// 创建axios实例
var instance = axios.create({
  timeout: 180000 // 请求超时时间
})
// 这是调用数据接口,公共接口url+调用接口名
// instance.defaults.baseURL = 'http://localhost:9527/'
// 生产环境
// instance.defaults.baseURL = 'http://39.108.54.33/api'
//测试环境
instance.defaults.baseURL = 'https://www.gxncnyz.com/api'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    let userId = sessionStorage.getItem('userId')
    if (!userId || new Date().getTime() > Cookies.get('token_time')) {
      // 重定向到登录页面
      router.push('/login')
    } else {
      config.headers.Authorization = 'Bearer ' + Cookies.get('token')
    }
    // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
    // post请求参数放在data里面，get请求参数放在params里
    if (config.method === 'post' || config.method === 'put') {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    } else {
      config.params = { ...config.params }
    }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

// http响应拦截器
instance.interceptors.response.use(
  response => {
    let data
    // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
    if (response.data === undefined) {
      data = JSON.parse(response.request.responseText)
    } else {
      data = response.data // 返回接口返回的结果信息
    }
    return data
  },
  err => {
    return Promise.reject(err) // 返回接口返回的错误信息
  }
)
export default instance
