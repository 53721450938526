<template>
  <div id="container">
    <el-row>
      <el-col :span="6" :offset="1">
        <el-progress
          :percentage="onePercentage"
          :color="oneCustomColors"
          :format="oneFormat"
          :stroke-width="3"
        ></el-progress>
      </el-col>
      <el-col :span="6" :offset="1">
        <el-progress
          :percentage="twoPercentage"
          :color="twoCustomColors"
          :format="twoFormat"
          :stroke-width="3"
        ></el-progress>
      </el-col>
      <el-col :span="6" :offset="1">
        <el-progress
          :percentage="ThreePercentage"
          :color="ThreeCustomColors"
          :format="ThreeFormat"
          :stroke-width="3"
        ></el-progress>
      </el-col>
      <el-col :span="2" :offset="1" style="line-height: 15px;">
        {{ content }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrength',
  model: {
    event: 'change',
    prop: 'password'
  },
  props: {
    //密码
    password: {
      type: [String, Boolean, Number, Object],
      required: true,
      default: ''
    }
  },
  watch: {
    password(newValue) {
      const mode = this.checkPasswordStrength(newValue)
      //逻辑处理
      switch (mode) {
        //初始化状态
        case 0:
          this.content = ''
          this.onePercentage = 0
          this.twoPercentage = 0
          this.ThreePercentage = 0
          break
        case 1:
          this.content = '弱'
          this.onePercentage = 100
          this.twoPercentage = 0
          this.ThreePercentage = 0
          break
        case 2:
          this.content = '中'
          this.onePercentage = 100
          this.twoPercentage = 100
          this.ThreePercentage = 0
          break
        case 3:
          this.content = '高'
          this.onePercentage = 100
          this.twoPercentage = 100
          this.ThreePercentage = 100
          break
      }
    }
  },
  data() {
    return {
      content: '',
      onePercentage: 0,
      twoPercentage: 0,
      ThreePercentage: 0,
      oneCustomColors: [{ color: '#f56c6c', percentage: 100 }],
      twoCustomColors: [{ color: '#e6a23c', percentage: 100 }],
      ThreeCustomColors: [{ color: '#67c23a', percentage: 100 }]
    }
  },
  methods: {
    oneFormat() {
      return ''
    },
    twoFormat() {
      return ''
    },
    ThreeFormat() {
      return ''
    },
    //密码强度验证
    checkPasswordStrength(value) {
      // 弱  只有数字、大写字母、小写字母、特殊字符其中一种
      let weak = /^[0-9]{6,16}$|^[A-Z]{6,16}$|^[a-z]{6,16}$|^[\W._!@#$%^&*`~()-+=]{6,16}$/
      // 中  有数字、大写字母、小写字母、特殊字符其中两种
      // eslint-disable-next-line no-control-regex
      let middle = /^(?!\d+$)(?![a-zA-Z]+$)[\da-zA-Z]{6,16}$|^(?!\d+$)(?![\x00-\xff]+$)[\d\x00-\xff]{6,16}$|^(?!a-zA-Z+$)(?![\x00-\xff]+$)[a-zA-Z\x00-\xff]{6,16}$/
      // 强 密码必须大于等于8位包含大写字母，小写字母，数字，特殊符号中四选三组合
      let strong = /^(?=.*[a-zA-Z])(?=.*[\W_!@#$%^&*`~()-+=])(?=.*\d)[^]{8,16}$|^(?=.*[a-zA-Z])(?=.*[\W_!@#$%^&*`~()-+=])[^]{8,16}$|^(?=.*\d)(?=.*[\W_!@#$%^&*`~()-+=])[^]{8,16}$|^(?=.*[a-zA-Z])(?=.*\d)[^]{8,16}$/
      let mode = 0
      //正则表达式验证符合要求的
      if (value.length < 6) {
        mode = 0
      } else if (weak.test(value)) {
        mode = 1
      } else if (middle.test(value)) {
        mode = 2
      } else if (strong.test(value)) {
        mode = 3
      }
      return mode
    }
  }
}
</script>

<style>
.el-progress__text {
  display: none;
}
.el-progress-bar {
  padding-right: 0px;
  margin: 0px;
}
</style>
