import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts' // 引入echarts图形报表工具
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css' // 引入font-awesome图标，补充饿了么图标不足
import api from './http'
import VueParticles from 'vue-particles'
Vue.config.productionTip = false

//  可通过this.$echarts使用
Vue.prototype.$echarts = echarts

// 引入data-v组件库
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.use(ElementUI)
Vue.use(api)
Vue.use(VueParticles)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
