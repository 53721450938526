import axios from '../http'

/** 机构管理模块 */

// 获取机构树信息
export const findOrgTree = () => {
  return axios({
    url: '/sys/org/tree',
    method: 'get'
  })
}

// 保存
export const save = (params, method) => {
  return axios({
    url: '/sys/org',
    method: method,
    data: params
  })
}

// 根据ID查询角色信息
export const getObj = id => {
  return axios({
    url: '/sys/org/' + id,
    method: 'get'
  })
}

// 根据ID查询角色信息
export const deleteObj = id => {
  return axios({
    url: '/sys/org/' + id,
    method: 'delete'
  })
}

//根据父机构ID获得机构信息
export const getOrgByPid = pid => {
  return axios({
    url: '/sys/org/childs/' + pid,
    method: 'get'
  })
}
