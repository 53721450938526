import axios from '../http'

/** 安全宣传管理模块 */

// 分页查询宣传资料信息
export const findPublicityPage = params => {
  return axios({
    url: '/biogas/publicity/page',
    method: 'get',
    params
  })
}

// 获取安全宣传资料详细信息
export const getPublicityInfo = publicityId => {
  return axios({
    url: '/biogas/publicity/detail/' + publicityId,
    method: 'get'
  })
}
