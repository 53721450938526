<template>
  <div
    class="headbar"
    :style="{ background: themeColor }"
    :class="
      $store.state.app.collapse ? 'position-collapse-left' : 'position-left'
    "
  >
    <!-- 导航收缩 -->
    <span class="hamburg">
      <el-menu
        class="el-menu-demo"
        :background-color="themeColor"
        text-color="#fff"
        :active-text-color="themeColor"
        mode="horizontal"
      >
        <el-menu-item index="1" @click="onCollapse"
          ><hamburger :isActive="collapse"></hamburger
        ></el-menu-item>
      </el-menu>
    </span>
    <span
      style="color: #fff;font-size: 24px;font-weight:bolder;letter-spacing:8px;"
      >广西农村户用沼气CCER示范平台</span
    >
    <!-- 工具栏 -->
    <span class="toolbar">
      <el-menu
        class="el-menu-demo"
        :background-color="themeColor"
        :text-color="themeColor"
        :active-text-color="themeColor"
        mode="horizontal"
      >
        <el-menu-item index="5" @click="openPwd">
          <!-- 用户信息 -->
          <i class="el-icon-edit-outline" style="color: #fff;"></i>
          <span class="logout">修改密码</span>
        </el-menu-item>
        <el-menu-item index="5" @click="logout">
          <!-- 用户信息 -->
          <i class="el-icon-monitor" style="color: #fff;"></i>
          <span class="logout">退出系统</span>
        </el-menu-item>
      </el-menu>
    </span>
    <el-dialog
      title="密码修改"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
      custom-class="dialog-mask"
    >
      <el-form
        :model="form"
        :rules="dataRule"
        ref="dataForm"
        label-width="80px"
        size="small"
        style="text-align:left;"
      >
        <el-form-item label="旧密码" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="密码不少于6位"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            type="password"
            placeholder="密码不少于6位"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码强度">
          <password-strength
            v-model="form.newPassword"
            style="padding-top: 10px;"
          ></password-strength>
          <span style="color:#666;"
            >密码由6-16个字符组成，为了您的账号安全，请不要使用全数字或全字母作为密码。</span
          >
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPwd">
          <el-input
            v-model="form.checkPwd"
            type="password"
            placeholder="确认密码与密码要保持一致"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm" :loading="editLoading"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Hamburger from '@/components/Hamburger'
import PasswordStrength from '@/components/PasswordStrength'
export default {
  components: {
    Hamburger,
    PasswordStrength
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value.length < 6 || value.length > 16) {
        callback(new Error('请输入6位 ~ 16位密码'))
      } else {
        if (this.form.checkPwd !== '') {
          this.$refs.dataForm.validateField('checkPwd')
        }
        callback()
      }
    }
    let validateCheckPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      editLoading: false,
      form: {
        password: '',
        newPassword: '',
        checkPwd: ''
      },
      dataRule: {
        password: [
          { required: true, message: '旧密码不能为空', trigger: 'blur' }
        ],
        newPassword: [{ validator: validatePass, trigger: 'blur' }],
        checkPwd: [{ validator: validateCheckPwd, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 折叠导航栏
    onCollapse: function() {
      this.$store.commit('onCollapse')
    },
    // 退出登录
    logout: function() {
      this.$confirm('确认退出系统吗?', '提示', {
        type: 'warning'
      }).then(() => {
        sessionStorage.removeItem('userId')
        this.$router.push('/login')
      })
    },
    // 打开修改密码窗口
    openPwd() {
      this.form = {
        password: '',
        newPassword: '',
        checkPwd: ''
      }
      this.dialogVisible = true
    },
    submitForm() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          this.$api.user.updatePassword(this.form).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  },
  computed: {
    ...mapState({
      themeColor: state => state.app.themeColor,
      collapse: state => state.app.collapse
    })
  }
}
</script>

<style>
.headbar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1030;
  height: 60px;
  line-height: 60px;
  border-color: rgba(180, 190, 190, 0.8);
  border-left-width: 1px;
  border-left-style: solid;
}
.hamburg,
.navbar {
  float: left;
}
.toolbar {
  float: right;
}
.lang-item {
  font-size: 16px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}
.lang-item:hover {
  font-size: 18px;
  background: #b0d6ce4d;
}
.logout {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.badge {
  line-height: 18px;
}
.position-left {
  left: 200px;
}
.position-collapse-left {
  left: 65px;
}
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
