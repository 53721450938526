/*
 * 接口统一集成模块
 */
import * as login from './moudules/login'
import * as user from './moudules/user'
import * as menu from './moudules/menu'
import * as org from './moudules/org'
import * as dict from './moudules/dict'
import * as role from './moudules/role'
import * as biogas from './moudules/biogas'
import * as report from './moudules/report'
import * as publicity from './moudules/publicity'
import * as gas from './moudules/gas'

// 默认全部导出
export default {
  login,
  user,
  menu,
  org,
  role,
  dict,
  biogas,
  report,
  publicity,
  gas
}
